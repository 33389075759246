


































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import { FeedbackReportService } from '@/services/abilityAssessment/feedback-report-service';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: {}
})
export default class typeManagement extends Vue {
  @Inject(FeedbackReportService)
  private feedbackReportService!: FeedbackReportService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0,
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  public statusOptions: any[] = [];
  public exportLoading: Boolean = false;

  public created() {
    this.getStatus();
    this.getList();
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 改变优先级
  public inputPriority(val: string) {
    if (val === "") {
      return;
    }
    if (Number(val) < 1) {
      this.form.priority = 1;
    }
  }
  // 导出
  public async exportClick() {
    if (!this.tableData.length) {
      this.$message({
        type: 'error',
        message: '当前无导出内容！'
      })
      return;
    }
    const params = {
      ...this.form,
      effectAt: this.form.windowsRange ? this.form.windowsRange[0] : "",
      expireAt: this.form.windowsRange ? this.form.windowsRange[1] : "",
      filename: `反馈报表_${new Date().getTime()}.xlsx`
    }
    delete params.current;
    delete params.size;
    delete params.total;
    delete params.windowsRange;
    this.exportLoading = true;
    await this.feedbackReportService.exportFile(params);
    this.$message({
      message: '导出成功',
      type: 'success'
    });
    this.exportLoading = false;
  }
  // 获取状态
  public async getStatus() {
    const res = await this.feedbackReportService.getStatus();
    if (res && res.length) {
      this.statusOptions = res.map(t => ({ label: t.displayName, value: t.name }));
    }
  }
  //获取数据源
  public async getList() {
    const params = {
      ...this.form,
      effectAt: this.form.windowsRange ? this.form.windowsRange[0] : "",
      expireAt: this.form.windowsRange ? this.form.windowsRange[1] : "",
    }
    delete params.windowsRange;
    this.loading = true;
    const res = await this.feedbackReportService.getFeedbackList(params);
    if (res) {
      this.tableData = res.records;
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }
    this.loading = false;
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
