import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class FeedbackReportService {
  @Inject(HttpService) private http!: HttpService;
  // 查询
  public getFeedbackList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-skill-backend/admin/report/feedback', param);
  }
  // 获取状态
  public getStatus(param?: Dict<any>): Promise<any> {
    return this.http.get(
      '/wecom-skill-backend/admin/assessment/complete/status',
      param
    );
  }
  // 导出
  public async exportFile(param: Dict<any>): Promise<any> {
    return this.http.downloadFile(
      `/wecom-skill-backend/admin/report/feedback/export`,
      param,
      param.filename
    );
  }
}
